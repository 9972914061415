<template>
  <div class="business-wrapper">
    <header :class="['header']">
      <div class="logo">
        <img src="../../assets/logo.png" />
      </div>
      <div class="nav-cont">
        <div class="nav" @click="jumpTo('Home')">首 页</div>
        <!-- <div class="nav" @click="jumpTo('Business')">主要业务</div> -->
        <div class="nav" @click="jumpTo('About')">关于我们</div>
        <div class="nav navActive">加入我们</div>
      </div>
    </header>
    <div class="join-banner">
      <img src="../../assets/join-banner.png" />
    </div>
    <div class="recruit-wrapper">
      <div class="recruit-cont">
        <div class="title">招聘信息</div>
        <el-collapse v-model="activeName" accordion class="recruit-collapse">
          <el-collapse-item title="java开发工程师（西安、成都、东莞）" name="1">
            <template slot="title">
              <div class="header-title">java开发工程师（西安、成都、东莞）</div>
              <div class="header-info">
                薪资：面议 经验：3年以上 工作地点：西安、成都、东莞
              </div>
            </template>
            <div class="title2">岗位职责：</div>
            1、负责企业运营支持应用、集成产品开发应用、整合营销应用、协同供应链应用、大数据应用等五大系统的业务逻辑编写，为前台开发提供服务API支撑；<br />
            2、抽象服务业务逻辑，形成通用的业务组件，能够为业务复用，提高开发的效率；<br />
            3、负责定位日常业务逻辑异常问题，并进行解决；<br />
            4、遵守公司相关前端技术规范，参与项目中的代码评审和检查；<br />
            5、收集与梳理项目中的遇到的前端技术问题、在小组内及时反馈并参与制定相关的解决方案；；<br />
            6、在一定程度上与平台组同事协作，共同开发和维护前端公用组件和模版。<br />
            <div class="title2">岗位技能要求：</div>
            1、三年及以上服务器端开发经验；<br />
            2、熟练掌握Java或.net语言。熟悉Spring MVC，Spring
            Boot,Mybatis;熟练掌握Oracle（DDL,DML）；或者熟练使用C#编程语言，熟悉.net/.net
            core, 掌握ASP.NET MVC, ASP.NET Web API框架；<br />
            3、熟悉Linux相关命令；<br />
            4、了解多线程环境编程；<br />
            5、了解RESTful框架；<br />
            6、熟悉Redis缓存；<br />
            7、对加密（AES,RSA）有一定了解；<br />
            8、熟悉软件项目开发流程；<br />
            9、熟练使用微服务架构、领域驱动设计、网关，开发高可用接口；<br />
            10、具备良好的需求分析能力和设计能力，善于主动推动项目进展并最终落地；<br />
            11、掌握ERP、BPM、CRM、v-work、VRS、SRM、MES等其中一个系统。<br />
          </el-collapse-item>
          <el-collapse-item
            title="WEB前端开发工程师（西安、成都、东莞）"
            name="2"
          >
            <template slot="title">
              <div class="header-title">
                WEB前端开发工程师（西安、成都、东莞）
              </div>
              <div class="header-info">
                薪资：面议 经验：3年以上 工作地点：西安、成都、东莞
              </div>
            </template>
            <div class="title2">岗位职责：</div>
            1、负责企业运营、产品研发、市场营销、供应链、大数据应用等五大领域的应用开发与运维（前端）；<br />
            2、负责搭建前端开发框架，并在项目中推进；<br />
            3、负责开发和维护前端公用组件和模版，并做好相应的版本控制；<br />
            4、负责制定相关前端技术规范，参与项目中的代码评审和检查；<br />
            5、收集与梳理项目中的遇到的前端技术问题、在小组内及时反馈并参与制定相关的解决方案；<br />
            6、遵守公司相关前端技术规范，参与项目中的代码评审和检查；<br />
            7、收集与梳理项目中的遇到的前端技术问题、在小组内及时反馈并参与制定相关的解决方案；<br />
            8、在一定程度上与平台组同事协作，共同开发和维护前端公用组件和模版。<br />
            <div class="title2">岗位技能要求：</div>
            1、三年及以上Web前端开发经验；<br />
            2、熟练掌握html5、css3、es6、Vue、React等前端开发技术；<br />
            3、精通各大浏览器兼容性问题处理、精通请求优化及加载优化；<br />
            4、对用户体验、交互操作流程、及用户需求有深刻的理解；<br />
            5、有服务端后端开发经验者优先；<br />
            6、掌握BPM、CRM、v-work、VRS、SRM等其中一个系统。<br />
          </el-collapse-item>

          <el-collapse-item title="系统运维工程师（东莞）" name="3">
            <template slot="title">
              <div class="header-title">系统运维工程师（东莞）</div>
              <div class="header-info">
                薪资：面议 经验：5年以上 工作地点：东莞
              </div>
            </template>
            <div class="title2">岗位职责：</div>
            1、负责系统管理及维护，主要包括日常维护、系统变更、问题处理、应急处置、技术支持、安装部署等，保障环境安全稳定运行；<br />
            2、保障业务服务器稳定的运行，对业务系统进行优化与故障处理。<br />
            <div class="title2">岗位技能要求：</div>
            1、全日制专科以上学历，5年以上系统运维工作经验，有一定的故障定位处理经验；<br />
            2、有运维自动化、监控系统等开发经验者优先；<br />
            3、熟悉目前市场上至少两个相关主流云平台的云服务操作与配置，比如阿里云、Amazon、腾讯云；<br />
            4、熟悉Linux
            下各种应用服务，包括multipath、lvm、haproxy、ntp、apache、tomcat、tcpdump、keepalived、rsync、iptables、ansible、nginx、zabbix、php、LVS、Mysql等使用和配置。<br />
          </el-collapse-item>

          <el-collapse-item title="ERP开发工程师（东莞）" name="4">
            <template slot="title">
              <div class="header-title">ERP开发工程师（东莞）</div>
              <div class="header-info">
                薪资：面议 经验：3年以上 工作地点：东莞
              </div>
            </template>
            <div class="title2">岗位职责：</div>
            1、主要从事ERP二次开发工作，在相关文档的指导下，独立、熟练的进行ERP二次开发程序代码实现工作；<br />
            2、负责与系统工程师沟通，整理出具体开发需求，设计出相应的实现、方案，并编写详细设计文档；<br />
            3、按照ISO文档格式要求，独立完成一定的文档编写任务，如开发文档、详细设计文档、用户手册等。<br />
            <div class="title2">岗位技能要求：</div>
            1、大专及以上学历，计算机、软件工程及相关专业，2-3年工作经验；<br />
            2、精通Oracle PLSQL编程，具备良好的编程习惯和风格；<br />
            3、熟悉Oracle ERP二次开发工具，ORACLE FORMREPORTXML Publisher等；<br />
            4、熟悉R12的开发，熟悉EBS中常用模块INV、WIP、PO、SRM、ASCP、OM、GL、AP、AR等模块中的两个以上；<br />
            5、较强的文档编写能力；<br />
            6、有OAF开发经验，熟悉Java优先。<br />
          </el-collapse-item>
          <el-collapse-item title="自动化测试工程师（深圳、东莞）" name="5">
            <template slot="title">
              <div class="header-title">自动化测试工程师（深圳、东莞）</div>
              <div class="header-info">
                薪资：面议 经验：3年以上 工作地点：深圳、东莞
              </div>
            </template>
            <div class="title2">岗位职责：</div>
            1、熟悉软件研发交付流程，测试理论及测试设计相关方法的运用；<br />
            2、参与产品需求分析和评审，开展产品测试分析，输出产品测试策略，测试方案，测试计划；<br />
            3、参与产品的测试用例设计，测试执行，开展产品质量分析，风险评估，并提出改进建议；<br />
            4、熟悉基于Web和APP的前后端测试，性能测试，自动化测试。<br />
            <div class="title2">岗位技能要求：</div>
            1、具备3年以上性能测试至少熟悉一种开发语言的运用（Java/Python）；<br />
            2、熟悉常用的Oracle/MySQL数据库的应用，能够在项目中熟练编写DDL，DML语句的应用；<br />
            3、熟悉基于Jenkins的持续集成并具有接口，Web自动化测试实践经验；<br />
            4、熟悉LoadRunner/Jmeter/Postman等常用工具的应用；<br />
            5、具有良好的沟通能力和逻辑思维能力，能够承受一定的工作压力。<br />
          </el-collapse-item>
          <el-collapse-item
            title="Java系统架构师 SA（西安、成都、东莞）"
            name="6"
          >
            <template slot="title">
              <div class="header-title">
                Java系统架构师 SA（西安、成都、东莞）
              </div>
              <div class="header-info">
                薪资：面议 经验：3年以上 工作地点：西安、成都、东莞
              </div>
            </template>
            <div class="title2">岗位职责：</div>
            1、负责系统分析、设计、开发及优化；<br />
            2、与团队共同协作解决技术难题；<br />
            3、支持系统测试、上线及运维工作。<br />
            <div class="title2">岗位技能要求：</div>
            1、6年以上互联网项目Java开发经验，编程基础扎实，熟悉多线程、集合和IO原理，了解常见的设计模式的使用场景；<br />
            2、熟悉主流的Java开源框架，如spring、springmvc、springboot、springcloud、mybatis等；<br />
            3、熟悉分布式系统开发, 高性能高并发编程，有实际开发经验；<br />
            4、了解并使用其中一种或多种中间件：Memcache/redis/ehcache、RabbitMQ/Kafka/ActiveMQ、zookeeper、workflow、HDFS/FastDFS、Solr/Lucene/ElasticSearch、Nginx反向代理/负载均衡；<br />
            5、熟悉oracle/mysql等主流数据库的设计和开发，有一定的sql优化经验，了解NoSQL数据库及其使用场景：mongodb等；<br />
            6、熟悉常用的开发工具，如Eclipse、IDEA、maven、git；<br />
            7、熟悉linux命令，能通过linux命令解决生产遇到的紧急问题；<br />
            8、熟悉常用的设计模式，并能够灵活运用到实际项目中；<br />
            9、具备良好的编码习惯及严谨的逻辑思维；<br />
            10、激情，有责任心，精益求精，有良好的客户服务意识和团队精神；<br />
            11、需要适应经常性出差。<br />
          </el-collapse-item>
          <el-collapse-item title="销售工程师" name="7">
            <template slot="title">
              <div class="header-title">销售工程师（西安、成都）</div>
              <div class="header-info">
                薪资：面议 经验：3年以上 工作地点：西安、成都
              </div>
            </template>
            <div class="title2">岗位职责：</div>
            1、推广PLM、物联网产品、商业智能等软件产品，建立客户关系，维护企业形象；<br />
            2、负责项目跟进和产品销售，完成销售任务；<br />
            3、收集和整理所辖区域或行业的客户信息，挖掘潜在需求；<br />
            4、团队合作共同策划各类市场活动，创造销售机会；<br />
            5、做好新客户的开拓与老客户的维护工作；<br />
            6、完成上级指派的任务。<br />
            <div class="title2">岗位技能要求：</div>
            1、专科及以上学历，品貌端正，机械制造、市场营销、计算机等相关专业；<br />
            2、有PDM、CAD、物联网、商业智能等软件使用及销售经验者优先考虑；<br />
            3、良好的表达与沟通能力；较强的工作能力和学习能力，有高度工作责任感和团队协作精神；<br />
            4、能适应短期出差。<br />
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
    <div class="footer">
      <div class="footer-cont">
        <div class="box1">
          <div>
            <svg
              version="1.0"
              id="图层_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 16 16"
              enable-background="new 0 0 16 16"
              xml:space="preserve"
            >
              <g>
                <path
                  fill="#B8BECC"
                  d="M14,3H2C1.45,3,1,3.45,1,4v8c0,0.55,0.45,1,1,1h12c0.55,0,1-0.45,1-1V4C15,3.45,14.55,3,14,3z M14,12H2V4
                h12V12z"
                />
                <path
                  fill="#B8BECC"
                  d="M6.41,8.5h6c0.28,0,0.5-0.22,0.5-0.5s-0.22-0.5-0.5-0.5h-6c-0.28,0-0.5,0.22-0.5,0.5S6.13,8.5,6.41,8.5z"
                />
                <path
                  fill="#B8BECC"
                  d="M12.48,9.79h-6c-0.28,0-0.5,0.22-0.5,0.5s0.22,0.5,0.5,0.5h6c0.28,0,0.5-0.22,0.5-0.5
                S12.75,9.79,12.48,9.79z"
                />
                <circle fill="#B8BECC" cx="4.33" cy="6.34" r="1" />
              </g>
            </svg>
            西安华越信息技术有限公司
          </div>
          <div>
            <svg
              version="1.0"
              id="图层_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 16 16"
              enable-background="new 0 0 16 16"
              xml:space="preserve"
            >
              <g>
                <path
                  fill="#B8BECC"
                  d="M8,1.5c-2.99,0-5.43,2.43-5.43,5.43c0,1.97,2.18,4.86,4,6.93C6.93,14.26,7.45,14.5,8,14.5
                  s1.07-0.24,1.43-0.64c1.83-2.07,4-4.96,4-6.93C13.43,3.93,10.99,1.5,8,1.5z M8.68,13.2c-0.35,0.39-1,0.39-1.35,0
                  C4.94,10.49,3.57,8.2,3.57,6.93C3.57,4.49,5.56,2.5,8,2.5s4.43,1.99,4.43,4.43C12.43,8.2,11.06,10.49,8.68,13.2z"
                />
                <path
                  fill="#B8BECC"
                  d="M8,4.5C6.62,4.5,5.5,5.62,5.5,7S6.62,9.5,8,9.5s2.5-1.12,2.5-2.5S9.38,4.5,8,4.5z M8,8.5
                  C7.17,8.5,6.5,7.83,6.5,7S7.17,5.5,8,5.5S9.5,6.17,9.5,7S8.83,8.5,8,8.5z"
                />
              </g>
            </svg>
            西安市高新高新区唐延南路与锦业路交汇处逸翠园i都会4号楼1单元2504
          </div>
          <div>
            <svg
              version="1.0"
              id="图层_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 16 16"
              enable-background="new 0 0 16 16"
              xml:space="preserve"
            >
              <path
                fill="#B8BECC"
                d="M13,9.56c-1.25-1.19-2.4-1.43-3.5-0.75C9.44,8.85,9.37,8.9,9.29,8.96L9.05,9.14C9.03,9.13,9,9.11,8.98,9.1
              c-0.43-0.33-0.82-0.7-1.16-1.12c-0.3-0.38-0.54-0.8-0.68-1.2L7.13,6.73l0.02-0.01c1.57-1.37,0.86-2.86,0.2-3.85
              C6.77,2,6.07,1.54,5.27,1.5h0c-0.82-0.04-1.41,0.32-2.1,0.9l-0.3,0.25C2.38,3.04,2.08,3.64,2.01,4.38C1.85,6.1,2.9,8.59,4.7,10.72
              c1.79,2.12,4.26,3.78,6.14,3.78c0.57,0,1.09-0.15,1.51-0.5l0.5-0.39c0.6-0.49,0.91-0.91,1.06-1.45C14.1,11.55,14.09,10.59,13,9.56z
              M12.96,11.89c-0.1,0.33-0.3,0.6-0.73,0.95l-0.5,0.39c-1.14,0.93-4.07-0.55-6.27-3.15c-1.61-1.9-2.59-4.16-2.45-5.6
              C3.05,4,3.22,3.65,3.5,3.42l0.31-0.26C4.45,2.62,4.79,2.5,5.15,2.5c0.02,0,0.05,0,0.07,0c0.48,0.02,0.9,0.32,1.3,0.92
              c0.89,1.33,0.67,1.94-0.01,2.53L6.04,6.36l0.04,0.28c0.02,0.11,0.04,0.22,0.08,0.34L6.2,7.09C6.37,7.6,6.68,8.14,7.05,8.61
              c0.39,0.48,0.84,0.91,1.32,1.28c0.1,0.08,0.21,0.15,0.34,0.24l0.37,0.22l0.81-0.59c0.05-0.04,0.1-0.07,0.15-0.1
              c0.51-0.32,1.14-0.46,2.28,0.63C12.9,10.84,13.11,11.36,12.96,11.89z"
              />
            </svg>
            电话：029-81149376
          </div>
          <div>
            <svg
              version="1.0"
              id="图层_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 16 16"
              enable-background="new 0 0 16 16"
              xml:space="preserve"
            >
              <path
                fill="#B8BECC"
                d="M13.5,3h-11c-0.55,0-1,0.45-1,1v8c0,0.55,0.45,1,1,1h11c0.55,0,1-0.45,1-1V4C14.5,3.45,14.05,3,13.5,3z
              M12.72,4l-4.4,4.07C8.14,8.23,7.87,8.23,7.69,8.09L3.28,4H12.72z M2.5,12V4.64l4.53,4.19c0.28,0.24,0.62,0.35,0.96,0.35
              c0.35,0,0.7-0.12,0.99-0.37l4.51-4.18V12H2.5z"
              />
            </svg>
            hr@xa-huayue.com
          </div>
        </div>
      </div>
      <div class="line"></div>
      <div class="footer-cont">
        <div class="box2">
          Copyright ©2014 - 2021 西安华越信息技术有限公司
          <a href="https://beian.miit.gov.cn/" target="_blank"
            >陕ICP备14005811号</a
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},

  data() {
    return {
      activeName: "1",
    };
  },

  computed: {},

  watch: {},

  methods: {
    jumpTo(routerName) {
      this.$router.push(routerName);
    },
  },

  created() {},

  mounted() {
    this.$router.afterEach(() => {
      window.scrollTo(0, 0);
    });
  },

  beforeDestroy() {},
};
</script> 

<style lang="scss" scoped>
.business-wrapper {
  padding-top: 60px;
  width: 100%;
  min-width: 1200px;
  touch-action: none;
  overflow: hidden;
  position: relative;
  .header {
    display: flex;
    justify-content: space-between;
    width: 100vw;
    height: 60px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999;
    padding: 0 35px 0 0px;
    background: #fff;
    box-sizing: border-box;
    box-shadow: 0px 0px 5px 5px rgba($color: #1f2642, $alpha: 0.1);
    transition: all 0.5s;
    .logo {
      width: 200px;
      > img {
        width: 100%;
      }
    }
    .nav-cont {
      width: 400px;
      height: 60px;
      .nav {
        float: left;
        height: 60px;
        line-height: 60px;
        color: #707070;
        padding: 0 15px;
        cursor: pointer;
        font-size: 18px;
      }
      .nav:hover {
        color: #0b57a3;
        border-bottom: 2px solid #0b57a3;
      }
      .navActive {
        color: #0b57a3;
        border-bottom: 2px solid #0b57a3;
      }
    }
  }
  .join-banner {
    width: 100%;
    > img {
      width: 100%;
      float: left;
    }
  }
  .join-banner::after {
    content: "";
    display: block;
    height: 0;
    clear: both;
  }
  .recruit-wrapper {
    padding: 50px 0;
    background: #f0f2f5;
    .recruit-cont {
      text-align: left;
      width: 1200px;
      margin: auto;
      .title {
        width: 100%;
        height: 45px;
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: bold;
        color: #282b33;
        line-height: 45px;
        text-align: center;
        margin-bottom: 40px;
      }
    }
  }

  .footer {
    background: #26282e;
    color: #a4a5a8;
    position: relative;
    overflow: hidden;
    height: 77px;
    svg {
      width: 16px;
      height: 16px;
      vertical-align: middle;
      margin-top: -2px;
    }
    .line {
      width: calc(100% - 120px);
      height: 1px;
      background: #494c4f;
      margin: 0 auto;
    }
    .footer-cont {
      width: 1200px;
      margin: auto;
      .box1 {
        padding: 10px 0;
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        line-height: 16px;
      }
      .box2 {
        padding: 15px 0;
        font-size: 12px;
        line-height: 16px;
        a {
          margin-left: 10px;
          color: #a4a5a8;
          text-decoration: none;
        }
      }
      background: #26282e;
    }
  }
}
</style>
<style lang="scss">
.recruit-collapse {
  .header-title {
    font-size: 18px;
    width: 50%;
    float: left;
  }
  .header-info {
    width: 50%;
    float: right;
    text-align: right;
    margin-right: 20px;
  }
  .title2 {
    height: 30px;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: bold;
    color: #526ecc;
    line-height: 30px;
    margin: 10px 0px;
  }
  .el-collapse-item {
    border-radius: 4px;
    overflow: hidden;
    margin-bottom: 20px;
    background: #fff;
  }
  .el-collapse-item__header {
    z-index: 99;
    position: absolute;
    width: 1200px;
    border-radius: 4px;
    padding: 0 20px;
  }
  .el-collapse-item__wrap {
    margin-top: 48px;
    padding: 20px;
  }
  .el-collapse-item__header.is-active {
    color: #526ecc;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  }
  .el-collapse-item__header::after {
    content: "";
    display: block;
    height: 48px;
  }
  .el-collapse-item {
    min-height: 48px;
  }
  .el-collapse-item__content {
    line-height: 30px;
    color: #6c7280;
  }
  // .el-icon-arrow-right:before {
  //     content: "\e6e0";
  //     border:1px solid #526ECC;
  //     border-radius: 50%;
  //     padding: 2px;
  // }
  .el-collapse-item__arrow {
    transform: rotate(90deg);
  }
  .el-collapse-item__arrow.is-active {
    transform: rotate(-90deg);
  }
}
</style>